import { QueryClient, QueryCache, QueryFunctionContext } from 'react-query'
import { ifElse, isNil, identity, has, is, pathOr } from 'ramda'
import { format, request } from '@shared/innmaxLib/services'
import toQueryString from '@shared/innmaxLib/utils/toQueryString'
import { message, destroy } from '@shared/innmaxUI/Message/Message'
import { AuthContextType } from '@services/auth'
import { persistQueryKeyStorage } from './usePersistQuery'
import history from '@shared/innmaxLib/routes/history'

type Options = [string, { [key: string]: any }]

const hasToken = ifElse(isNil, identity, has('token'))

// Define a default query function that will receive the query key
async function defaultQueryFn({ queryKey }: QueryFunctionContext<Options>) {
  let _queryKey: any = queryKey

  if (is(String, _queryKey)) {
    _queryKey = [_queryKey]
  }

  const [url, { ..._params } = {}] = _queryKey

  const { fullUrl, params } = format(url, { ..._params })

  // const controller = new AbortController()
  // const signal = controller.signal

  // delete params['pageNum']
  // delete params['pageSize']
  delete params['current']
  delete params['size']

  const isQueryUrl = url.indexOf('?') > -1
  const promise = !isQueryUrl
    ? request(`${fullUrl}?${toQueryString(params)}`)
    : request(`${fullUrl}`)

  // promise.cancel = () => signal.abort()

  return promise
}

// async function defaultMutationFn() {}

const onError = ({ error, auth }: { error: any; auth: AuthContextType }) => {
  const { logout } = auth
  if (error.error?.code === -8) {
    logout({
      onSuccess: message({
        content: '登入已過期，請重新登入',
        maxCount: 1,
        type: 'error',
        top: 100,
        onClose: () => {
          destroy()
        },
      }),
    })
  }
}

type CreateQueryClientOptions = {
  /**
   * default is true
   */
  refetchPersistOnError: boolean
}

export let queryClient: QueryClient

function refetchPersistIfNeed(error: any) {
  const errorCode: number | null = pathOr(null, ['error', 'code'], error)
  if (isNil(errorCode)) {
    return
  }
  const queryKeys = persistQueryKeyStorage.getQueryKey(errorCode)
  queryKeys.forEach(async queryKey => {
    try {
      queryClient.invalidateQueries(queryKey, {
        refetchInactive: true,
      })
    } catch (error) {
      console.error('invalidate queries error:', error)
    }
  })
}

export default function createQueryClient(
  auth: AuthContextType,
  options?: CreateQueryClientOptions
) {
  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        mutations: {
          onError: (error: any) => {
            onError({ auth, error })
            if (options?.refetchPersistOnError || true) {
              refetchPersistIfNeed(error)
            }
          },
        },
        queries: {
          onError: (error: any) => onError({ auth, error }),
          queryFn: defaultQueryFn,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          refetchInterval: false,
          retry: false,
        },
      },
      queryCache: new QueryCache({
        onError: (error: any) => onError({ auth, error }),
      }),
    })
  } else {
    queryClient.getQueryCache().config.onError = (error: any) =>
      onError({ auth, error })
    queryClient.setDefaultOptions({
      mutations: {
        onError: (error: any) => {
          onError({ auth, error })
          if (options?.refetchPersistOnError || true) {
            refetchPersistIfNeed(error)
          }
        },
      },
      queries: {
        onError: (error: any) => onError({ auth, error }),
        queryFn: defaultQueryFn,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: false,
        retry: false,
      },
    })
  }

  return queryClient
}
