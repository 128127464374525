import { useQuery, UseQueryOptions } from 'react-query'
import React from 'react'
import Store from 'store'
import { path } from 'ramda'
import { request, useMakeMutation } from '@shared/innmaxLib/services'
import type { DataSource, MutationOptions } from '@shared/innmaxLib/services'
import env from '@shared/env'
import type { IRepair } from './repair.types'
import { useConvertIRepairs, useConvertIRepair } from './repair.converter'
import { IQueryProps } from '@services/report'
import { QueryVariables } from '@shared/innmaxLib/services'

/**
 * 維修查詢
 */
export const useRepairInquiry = (
  query?: any,
  options?: UseQueryOptions<DataSource<IRepair>>
) => {
  const { data, ...others } = useQuery(['/repairTicket/repair/search', query], {
    ...options,
    cacheTime: 0,
  })

  const { dataSource } = useConvertIRepairs(data)

  return {
    ...others,
    dataSource,
  }
}
/**
 * state :報修=reported, 維修=repairing, 結案=close,finish
 */
export const useRepairList = (
  query?: any,
  options?: UseQueryOptions<DataSource<IRepair>>
) => {
  const { data, ...others } = useQuery(
    ['/repairTicket/repair/search', { ...query }],
    {
      ...options,
      cacheTime: 0,
    }
  )

  const { dataSource } = useConvertIRepairs(data)

  return {
    ...others,
    dataSource,
  }
}

export const useRepair = (id?: React.Key) => {
  const { data, refetch, isLoading, ...others } = useQuery(
    [`/repairTicket/repair/{id}`, { id }],
    { enabled: !!id, cacheTime: 0, select: res => path(['data'], res) }
  )

  const { data: repairData } = useConvertIRepair(data)

  return {
    ...others,
    refetch,
    loading: isLoading,
    data: repairData as IRepair,
  }
}

export const createReportUri = '/repairTicket/repair'

export const createReport = (values: Partial<IRepair>) =>
  request(createReportUri, { method: 'POST', body: values })

export const updateReportUri = '/repairTicket/repair/reported/edit'

export const updateReport = (values: Partial<IRepair> = {}) =>
  request(updateReportUri, { method: 'PUT', body: values })

export const dispatchReport = (values: Partial<IRepair> = {}) =>
  request(`/repairTicket/repair/reported/dispatch`, {
    method: 'PUT',
    body: values,
  })

export const updateRepairing = (values: Partial<IRepair> = {}) =>
  request(`/repairTicket/repair/repairing/edit`, {
    method: 'PUT',
    body: values,
  })

export const closeReport = (values: Partial<IRepair> = {}) =>
  request(`/repairTicket/repair/reported/close`, {
    method: 'PUT',
    body: values,
  })

export const closeRepairing = (values: Partial<IRepair> = {}) =>
  request(`/repairTicket/repair/repairing/repaired`, {
    method: 'PUT',
    body: values,
  })

export const returnDispatch = (values: Partial<IRepair> = {}) =>
  request(`/repairTicket/repair/repairing/undispatch`, {
    method: 'PUT',
    body: values,
  })

export const useAddReport = (options: MutationOptions = {}) => {
  return useMakeMutation(createReport, options)
}

export const useUpdateReport = (options: MutationOptions = {}) => {
  return useMakeMutation(updateReport, options)
}

export const useDispatchReport = (options: MutationOptions = {}) => {
  return useMakeMutation(dispatchReport, options)
}
//
export const useUpdateRepairing = (options: MutationOptions = {}) => {
  return useMakeMutation(updateRepairing, options)
}

export const useCloseReport = (options: MutationOptions = {}) => {
  return useMakeMutation(closeReport, options)
}

export const useCloseRepairing = (options: MutationOptions = {}) => {
  return useMakeMutation(closeRepairing, options)
}

export const useReturnDispatch = (options: MutationOptions = {}) => {
  return useMakeMutation(returnDispatch, options)
}

/**
 *
 * @param uploadOwner: string
 * @returns
 */
export const uploadFile = async ({ file, uploadOwner }: any) => {
  //convert base64 to File
  const newFile = await fetch(file.base64)
    .then(function (res) {
      return res.arrayBuffer()
    })
    .then(function (buf) {
      return new File([buf], file.name, { type: 'image/jpeg' })
    })

  const formData = new FormData()
  formData.append('file', newFile)

  return fetch(`${env.apiBaseUrl}/upload/${uploadOwner}/image`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${Store.get('sctc_token')}` as NonNullable<string>,
      Accept: 'application/json',
    },
  })
    .then(async (response: Response) => {
      let result: any

      if (response.status === 404) {
        return Promise.reject(response.statusText)
      }

      result = await response.json()
      return result
    })
    .catch((err: Error) => {
      console.error(`[uploaded media failed] ${err?.message}`)
      return err
    })
}

export const useExportRepairRpt = (
  query?: IQueryProps,
  options?: QueryVariables
) => {
  const [enabled, setIsEnabled] = React.useState(false)

  const { isLoading } = useQuery(
    [
      '/repairTicket/repair/search/export',
      { ...query, pageNum: undefined, pageSize: undefined },
    ],
    {
      ...options,
      enabled,
      onSuccess: (response: any) => {
        if (options?.onSuccess) {
          options.onSuccess(response)
        }
        setIsEnabled(false)
      },
      onError: error => {
        console.log(error)
        setIsEnabled(false)
      },
    }
  )

  return [
    isLoading,
    () => {
      setIsEnabled(true)
    },
  ] as [boolean, () => void]
}
