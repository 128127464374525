import { DeviceActive, DeviceType } from '@services/constants'

export interface DisplayScreen {
  active: DeviceActive
  address: string
  alarmCount: number
  contentName?: string
  contentSeq?: number
  deviceId: string
  deviceName: string
  deviceType: DeviceType
  displayName: string
  expiredDate: moment.Moment
  extra: any
  id: number
  installTime: moment.Moment
  isAlarm: boolean
  isRepair: boolean
  isSubAlarm: boolean
  isSubRepair: boolean
  lat: number
  lon: number
  parentDeviceDisplayName: string
  parentDeviceSeq: number
  parentDeviceType: DeviceType
  properties: {
    contentInfo: {
      contentName: string
      contentSeq: number
      contentUrl: string
      template: string
      weatherStationDispalyName: string
      weatherStationSeq: number
    }
    displayStartTime: string
    displayEndTime: string
    imgs: {
      filePath?: string
      tag?: string
      url?: string
    }
  }
  remark: string
  repairCount: number
  sessionId: number
  subAlarmCount: number
  subDeviceCount: number
  subRepairCount: number
  updateTime: moment.Moment
  template?: string
}

export type Content = {
  id: number
  name: string
  template: string
  index?: number //前端使用
}

export type Marquee = {
  active: DisplayScreenStatus
  text: string
  index?: number //前端使用
  marqueeType?: string
  cmd?: string
}

export type SourceBody = {
  fileHash: string
  length: number
  name: string
  type: string
  url: string
}

export type Media = {
  active: DisplayScreenStatus
  name: string
  playCount: number
  playSpeed: number
  sources: SourceBody[]
  index?: number //前端使用
  id?: number //前端使用
}

export type ContentBody = {
  active: string
  marquees: Marquee[]
  marqueesPlaySpeed: number
  medias: Media[]
  name: string
  template: string
}

export type ContentDetail = {
  active: string
  createAt: number
  id: number
  marquees: Marquee[]
  marqueesPlaySpeed: number
  medias: Media[]
  name: string
  template: string
  updateAt: number
  url: string
  wholePointMedia: Media
}

export const DisplayScreenStatusEnum = {
  Delete: -1,
  Deactivate: 0,
  Active: 1,
}

export type DisplayScreenStatus = -1 | 0 | 1

export type DisplayScreenSyncMedia = {
  active: DisplayScreenStatus
  name: string
  playCount: number
  playSpeed: number
  sources: [
    {
      fileHash: string
      length: number
      name: string
      type: string
      url: string
    }
  ]
}

export type DisplayScreenSyncMarquee = {
  active: DisplayScreenStatus
  text: string
}

export type DisplayScreenSync = {
  active: DisplayScreenStatus
  address: string
  content: {
    active: DisplayScreenStatus
    createAt: number
    extraUpdateAt: number
    id: number
    marquees: DisplayScreenSyncMarquee[]
    marqueesPlaySpeed: number
    medias: DisplayScreenSyncMedia[]
    name: string
    template: string
    updateAt: number
  }
  contentSeq: number
  deviceId: string
  deviceName: string
  deviceType: string
  displayName: string
  expiredDate: number
  id: number
  installTime: number
  isAlarm: true
  isDeviceFail: false
  lat: number
  lightNo: string
  lightSeq: number
  lon: number
  nextSyncAfterSec: number
  remark: string
  sessionId: number
  updateTime: number
  properties: {
    contentInfo: {
      contentName: string
      contentSeq: number
      contentUrl: string
      template: string
      weatherStationDispalyName: string
      weatherStationSeq: number
      defaultImgUrl: string
    }
    displayStartTime: string
    displayEndTime: string
    imgs: any
  }
}

export type IDisplayScreenCtrlerTypeColumns = {
  colName: string
  required: boolean
  enums?: string[]
  doc: string
}

export type IDisplayScreenCtrlerType = {
  ctrlerType: string
  columns: IDisplayScreenCtrlerTypeColumns[]
}
