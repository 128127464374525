import { Device, useDeviceInfos } from '@services/device'
import { ISession, useSessionTree } from '@services/session'
import { Skeleton } from 'antd'
import React from 'react'
import { googleMapOptions } from '@services/constants/map'
import { Loading } from '@shared/innmaxUI'
import { SmartMap, SmartMarker } from '@shared/innmaxMap/map/SmartMap'
import { css } from 'styled-components'
import { DensityColor } from '@shared/innmaxMap/map/GoogleMap/DensityOverlay'

const EmbedMap = () => {
  const { baseLayer, byId, loading: loadingSessionTree } = useSessionTree()
  const {
    queryDeviceInfos,
    dataSource: devices,
    loading: loadingDevices,
  } = useDeviceInfos()

  const [currentSession, _setCurrentSession] = React.useState<ISession | null>(
    null
  )

  const [sessionDevices, setSessionDevices] = React.useState<Device[]>([])

  const setCurrentSession = React.useCallback((session: ISession) => {
    setSessionDevices([])
    _setCurrentSession(session)
  }, [])

  React.useEffect(() => {
    if (loadingSessionTree) {
      return
    }
    if (!baseLayer) {
      return
    }
    if (baseLayer.id === currentSession?.id) {
      return
    }
    setCurrentSession(baseLayer)
  }, [baseLayer])

  React.useEffect(() => {
    if (currentSession === null) {
      return
    }
    queryDeviceInfos({
      sessionId: currentSession.id,
    })
  }, [currentSession])

  React.useEffect(() => {
    if (loadingDevices) {
      return
    }
    if (!devices || devices.length === 0) {
      setSessionDevices([])
      return
    }
    setSessionDevices(devices || [])
  }, [devices])

  return (
    <div
      css={css`
        height: 100vh;
        width: 100vw;
      `}>
      <div>
        {currentSession ? (
          <SmartMap
            {...currentSession}
            isBaseLayer={baseLayer?.id === currentSession?.id}
            mapType={currentSession?.type}
            id="map"
            center={
              currentSession?.lat &&
              currentSession?.lon && {
                lat: currentSession?.lat,
                lng: currentSession?.lon,
              }
            }
            zoom={currentSession?.zoom}
            googleMapOptions={{
              ...googleMapOptions,
              streetViewControl: false,
              backRectangleSelectControl: false,
              satelliteControl: false,
              filterControl: false,
              layerControl: false,
              layerControlOptions: false,
              placeSearchControl: false,
              filterControlOptions: false,
              zoomControl: false,
            }}
            mapContainerStyle={{
              height: '85vh',
              width: '100%',
            }}
            loading={loadingSessionTree}
            renderLoading={
              <Loading
                style={{ height: 'calc(90vh - 64px)', alignItems: 'center' }}
              />
            }>
            <>
              {devices.map((device: Device) => (
                <SmartMarker
                  key={`${device.deviceType}-${device.id}`}
                  mapType={currentSession.type}
                  item={device}
                  enableClusterer={false}
                />
              ))}
            </>
          </SmartMap>
        ) : (
          <Skeleton />
        )}
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding: 10px;
          height: 10vh;
        `}>
        {[
          {
            color: DensityColor.High,
            title: '壅塞',
          },
          {
            color: DensityColor.Medium,
            title: '人多',
          },
          {
            color: DensityColor.Normal,
            title: '適中',
          },
        ].map((data, index) => (
          <div
            key={data.color}
            className="flex-1 flex justify-center items-center
          ">
            <div
              style={{
                backgroundColor: data.color,
                height: '5vh',
                width: '40%',
              }}></div>
            <span
              css={css`
                margin-left: 5px;
                font-size: 14px;
              `}>
              {data.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default EmbedMap
