import styled from 'styled-components'
import { Empty } from 'antd'
import { useTranslation } from 'react-i18next'
import { LeafletComponentProps } from '../Leaflet/Leaflet'
import { GoogleMapComponentProps } from '../GoogleMap/GoogleMap'
import Leaflet from '../Leaflet'
import GoogleMap from '../GoogleMap'
import { MapType } from '../map.types'
import { IMapLibreContainerProps } from '../MapLibre/MapContainer'
import MapLibre from '../MapLibre'
import React from 'react'

type AllOptional<
  Type =
    | LeafletComponentProps
    | GoogleMapComponentProps
    | IMapLibreContainerProps
> = {
  [Property in keyof Type]?: Type[Property]
} & {
  type: MapType
  children?: React.ReactNode | undefined
  googleMapOptions?: any
  leafletMapOptions?: any
  mapLibreOptions?: IMapLibreContainerProps
  onLoad?: (map: any) => any
  onRectangleComplete?: (rectangle: any) => any
  onRectangleClick?: () => void
  onFilterControlClick?: () => void
  onLayerControlClick?: () => void
}

const EmptyContent = () => {
  const { t } = useTranslation()
  return (
    <Flex>
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('common:no map')}
        style={{ color: '#4a4a4a' }}
      />
    </Flex>
  )
}

function SmartMapFC<T>(props: AllOptional<T>, ref: React.ForwardedRef<any>) {
  return props.type === MapType.GoogleMap ? (
    <GoogleMap ref={ref} options={props?.googleMapOptions} {...props}>
      {props.children}
    </GoogleMap>
  ) : props.type === MapType.Upload ? (
    <MapLibre
      ref={ref}
      mapType={props.type}
      {...props?.mapLibreOptions}
      {...props}>
      {props.children}
    </MapLibre>
  ) : (
    <EmptyContent />
  )
}

export const SmartMap = React.forwardRef(SmartMapFC) as <T>(
  props: AllOptional<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof SmartMapFC>

export const SmartMarker = (props: any) => {
  return props.mapType ? (
    props.mapType === MapType.Upload ? (
      props?.item?.isBuilding ? (
        <MapLibre.SessionMarker {...props} />
      ) : (
        <MapLibre.DeviceMarker {...props} />
      )
    ) : props?.item.isBuilding ? (
      <GoogleMap.AreaMarker {...props} />
    ) : (
      <GoogleMap.Marker {...props} />
    )
  ) : (
    <></>
  )
}

export { default } from './Utils'

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
`
