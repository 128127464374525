import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { toThousandSeparator } from '@shared/utils/webHelper'

import type { ICommonTitle } from './type'

export default function CommonTitle({
  yAxisDateStr,
  sessionName,
  yAxisName,
  total,
}: ICommonTitle) {
  const { t } = useTranslation()

  return (
    <Col span={24} className="mb-22 flex items-center">
      <div className="font-medium text-lg text-darkGrey">{yAxisDateStr}</div>
      <div className="ml-8 font-medium text-lg text-gamboge">
        {sessionName ? sessionName : ''} {yAxisName}
      </div>
      <div className="ml-16 text-[15px] font-medium  text-grey">
        {t('report:total count', {
          total: toThousandSeparator(total),
        })}
      </div>
    </Col>
  )
}
