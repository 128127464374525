import React from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useLazyQuery, QueryVariables } from '@shared/innmaxLib/services'
import { compose, path, pathOr, propEq, find } from 'ramda'

import {
  ICameraCtrlerType,
  ICameraCtrlerTypeColumns,
  Streaming,
} from '../camera/camera.types'

/**
 *  緊急求助設備 控制器類型清單
 */
export const useSosdevicCtrlerType = (options: QueryVariables = {}) => {
  const { data, ...others } = useQuery(
    '/device/sosdevice/ctrler/types',
    options
  )

  const dataSource = React.useMemo(() => {
    return pathOr<any[]>([], ['data'], data).map(type => ({
      ...type,
      columns: type.columns.map((column: any) => ({
        ...column,
        required: !column.opt,
      })),
    }))
  }, [data])

  const getSosdeviceCtrlerTypeColumnsByType = React.useCallback(
    (type: string) => {
      if (!type) {
        return []
      }

      const columns: ICameraCtrlerTypeColumns[] = compose<any, any, any>(
        pathOr([], ['columns']),
        find(propEq('ctrlerType', type))
      )(dataSource)
      return columns
    },
    [dataSource]
  )

  return {
    getSosdeviceCtrlerTypeColumnsByType,
    ...others,
    loading: others.isLoading,
    dataSource,
  }
}
