import { Device } from '@services/device'
import { ISession } from '@services/session'
import { isNil } from 'ramda'
import { MapType } from '../map.types'

function isLatLon(position: Device) {
  if (
    'lat' in position &&
    'lon' in position &&
    !isNil(position.lat) &&
    !isNil(position.lon)
  ) {
    return true
  }
  return false
}

function isXY(position: Device) {
  if (
    'x' in position &&
    'y' in position &&
    !isNil(position.x) &&
    !isNil(position.y)
  ) {
    return true
  }
  return false
}

const SmartMapUtils = {
  isValidDevicePosition: (session: ISession | undefined, item: Device) => {
    if (session?.type === MapType.GoogleMap) {
      return isLatLon(item)
    }
    if (session?.type === MapType.Upload) {
      return isXY(item)
    }
    return false
  },
}

export default SmartMapUtils
