import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useDisplayScreenControllerType } from '@services/device'

export const DisplayScreenCtrlerType = (props: SelectProps) => {
  const { onChange, value, ...others } = props

  const { t } = useTranslation()

  const { dataSource } = useDisplayScreenControllerType()

  return (
    <Select
      allowClear
      placeholder={'選擇控制器類型'}
      onChange={(type, option) => {
        onChange && onChange(type, option)
      }}
      value={value}
      {...others}>
      {(dataSource || []).map(d => (
        <Select.Option key={d.ctrlerType} value={d.ctrlerType}>
          {d.ctrlerType}
        </Select.Option>
      ))}
    </Select>
  )
}

export default DisplayScreenCtrlerType
