import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select, SelectProps } from '@shared/Form/Select'
import { useEpaperContentList } from '@services/device'
import { pathOr } from 'ramda'

const EPaperContentSelect = React.forwardRef<
  typeof Select,
  SelectProps<number>
>((props: SelectProps<number>, ref): any => {
  const { t } = useTranslation()
  const { dataSource, loading } = useEpaperContentList({
    variables: { pageSize: 999 },
  })

  return (
    <Select
      ref={ref as any}
      loading={loading}
      allowClear
      placeholder={t('displayscreen:mediaType')}
      {...props}>
      {pathOr([], ['content'], dataSource).map((x: any) => (
        <Select.Option key={x.id} value={x.id}>
          {x.name}
        </Select.Option>
      ))}
    </Select>
  )
})

export default EPaperContentSelect
