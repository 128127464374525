import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { slice } from 'ramda'
import { ColumnProps } from 'antd/lib/table'
import { ReportTable as Table } from '@shared/Table'

import { useIllegalParkingReport } from '@services/report'
import ReportTitle from '../CommonTitle'
import type { IAlarmProps } from '../type'
import { momentToString } from '@shared/innmaxLib/utils/webHelper'

export const pageSize = 10
function ReportTable({ queryVariables, ...props }: IAlarmProps) {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = React.useState(1)

  const { dataSource, isLoading } = useIllegalParkingReport(queryVariables)

  const columns: ColumnProps<any>[] = [
    {
      dataIndex: 'displayName',
      title: '偵測設備',
      align: 'center',
      width: 120,
    },
    {
      dataIndex: 'license',
      title: '車號',
      align: 'center',
      width: 120,
    },
    {
      dataIndex: 'parkingAt',
      title: '停車時間',
      align: 'center',
      width: 120,
      render: date => momentToString(date),
    },
    {
      dataIndex: 'leaveAt',
      title: '駛離時間',
      align: 'center',
      width: 120,
      render: date => momentToString(date),
    },
    {
      dataIndex: 'remark',
      title: '備註',
      align: 'center',
      width: 220,
    },
  ]

  return (
    <Row className="mt-42">
      <ReportTitle total={dataSource?.content?.length} {...props} />
      <Col span={24}>
        <Table
          rowKey="id"
          dataSource={{
            ...(dataSource as any),
            page: currentPage,
            content: slice(
              (currentPage - 1) * pageSize,
              currentPage * pageSize,
              dataSource?.content
            ),
          }}
          loading={isLoading}
          columns={columns}
          pagination={{
            showSizeChanger: false,
            total: dataSource?.content?.length || 1,
            pageSize,
          }}
          onChange={(p: any) => setCurrentPage(p.current || 1)}
        />
      </Col>
    </Row>
  )
}

export default React.memo(ReportTable)
