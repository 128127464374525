import { imageUrlPrefix } from '@shared/env'
import { Device } from '@services/device'

export enum MarkerImageType {
  PNG = '.png',
  SVG = '.svg',
}

export function markerIcon(
  d: Device,
  imageType: MarkerImageType = MarkerImageType.PNG
) {
  const status = d?.isRepair ? 'repair' : d?.isAlarm ? 'alarm' : 'normal'

  return `${imageUrlPrefix}/marker/${d?.deviceType}/normal${imageType}#${d?.deviceType}-${d?.id}-${status}`
}
