export { MapType } from '@shared/innmaxMap/map/map.types'

export const googleMapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  controlSize: 28,
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {
          hue: '#ffbb00',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'geometry.fill',
      stylers: [
        {
          hue: '#ffbb00',
        },
      ],
    },
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [
        {
          hue: '#ffbb00',
        },
      ],
    },
  ],
}

export const googleMapOptionsWithSimplified = {
  ...googleMapOptions,
}

export const leafletMapOptions = {}
