import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import cx from 'classnames'
import moment from 'moment'
import useSearch from '@shared/innmaxLib/hooks/useSearch'
import { Button } from '@shared/Form'
import Box from '@shared/innmaxUI/Box'
import { downloadFile } from '@shared/utils/webHelper'

import { FormItem } from 'src/components/Form'
import {
  useExportAlarmRpt,
  TYPE,
  TAB,
  startDateTime,
  endDateTime,
  conditionDate,
} from '@services/report'
import OptionsSelect from 'src/components/OptionsSelect'
import AlarmReport from './AlarmReport'
import AlarmBarChart from './AlarmBarChart'
import { TabPane, TabContainer } from '../style'
import { useAllSessions } from '@services/session'
import { IAlarmProps } from '../type'

const initialReportType = {
  reportType: TYPE.YEAR,
  conditionDate: conditionDate(TYPE.YEAR),
  start: startDateTime(TYPE.YEAR),
  end: endDateTime(TYPE.YEAR),
}

function Alarm() {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [currentTab, setCurrentTab] = React.useState(TAB.BAR_CHART)
  const [currentType, setCurrentType] = React.useState(TYPE.YEAR)

  const { byId } = useAllSessions()

  const { query, handleSearch } = useSearch({
    form,
    initial: initialReportType,
    transform: values => {
      const { reportTime, ...others } = values
      return {
        ...others,
        ...reportTime,
        start: reportTime.start.valueOf(),
        end: reportTime.end.valueOf(),
      }
    },
  })

  const [, handleExport] = useExportAlarmRpt(query, {
    onSuccess: (blob: any) => {
      downloadFile({
        blob,
        filename: 'report',
      })
    },
  })

  const handleTab = (target: number) => () => {
    setCurrentTab(target)
  }

  const handleReportType = (val: any) => {
    setCurrentType(val?.reportType)
    return val
  }

  const yAxisDateStr = React.useMemo(() => {
    switch (query.reportType) {
      case TYPE.YEAR:
        return t('report:year', { num: query.conditionDate })
      case TYPE.MONTH:
        return t('report:month', { num: query.conditionDate.split('/')[1] }) //YYYY/MM
      case TYPE.DAY:
        const startDate = moment(query.start).format('YYYY.MM.DD')
        const endDate = moment(query.end).format('YYYY.MM.DD')
        return `${startDate} - ${endDate} `
      default:
        return ''
    }
  }, [query])

  const sessionName = React.useMemo(
    () => query?.sessionId && byId[query.sessionId].name,
    [query]
  )

  const props: IAlarmProps = React.useMemo(
    () => ({
      queryVariables: { ...query, deviceType: query.input },
      sessionName,
      yAxisDateStr,
      yAxisName: t('report:total number of alarm'),
    }),
    [query, yAxisDateStr, sessionName]
  )

  return (
    <div className="mx-24">
      <Box
        bg="#fff"
        borderRadius={8}
        mb={24}
        boxShadow="0 2px 10px 0 rgba(0,0,0,0.1)"
        padding="24px 32px 32px">
        <Form layout="inline" form={form}>
          <FormItem name="input" label={t('report:device')} className="w-144">
            <OptionsSelect.ReportDeviceSelect />
          </FormItem>
          <FormItem label={t('report:area')} name="sessionId" className="w-144">
            <OptionsSelect.SessionSelect />
          </FormItem>
          <FormItem
            label={`${t('report:report type')} / ${t('report:time')}`}
            name="reportTime"
            initialValue={initialReportType}
            className={currentType === TYPE.DAY ? 'w-[380px]' : 'w-[250px]'}
            getValueFromEvent={handleReportType}>
            <OptionsSelect.ReportRangeTimeSelect className="w-[380px]" />
          </FormItem>
          <FormItem.Button>
            <Button type="primary" className="w-96" onClick={handleSearch}>
              {t('common:search')}
            </Button>
          </FormItem.Button>
          <FormItem.Button>
            <Button
              type="primary"
              ghost
              className="w-96"
              onClick={handleExport}>
              {t('report:export')}
            </Button>
          </FormItem.Button>
        </Form>
      </Box>
      <TabContainer style={{ minHeight: '100%' }}>
        <TabPane>
          <div
            onClick={handleTab(TAB.BAR_CHART)}
            className={cx('tab__item', {
              'is--active': currentTab === TAB.BAR_CHART,
            })}>
            {t('report:chart mode')}
          </div>
          <div
            onClick={handleTab(TAB.LIST)}
            className={cx('tab__item', {
              'is--active': currentTab === TAB.LIST,
            })}>
            {t('report:report mode')}
          </div>
        </TabPane>
        {currentTab === TAB.LIST && <AlarmReport {...props} />}
        {currentTab === TAB.BAR_CHART && <AlarmBarChart {...props} />}
      </TabContainer>
    </div>
  )
}

export default React.memo(Alarm)
