import { useQuery, UseQueryOptions } from 'react-query'
import {
  convertData,
  DataSource,
  QueryVariables,
} from '@shared/innmaxLib/services'
import { isNil } from '@shared/utils/webHelper'
import React from 'react'
// import { REPORT_TYPE_REQ_VAL } from './report.enum'

const useReportQuery = (
  url: string,
  querys: { [key: string]: any },
  options?: UseQueryOptions
) => {
  const _querys = React.useMemo(() => {
    return isNil(querys)
      ? null
      : {
          ...querys,
          //   reportType: REPORT_TYPE_REQ_VAL[querys.reportType],
        }
  }, [querys])

  return useQuery([url, _querys], {
    enabled: !isNil(_querys),
    ...options,
  })
}

export const useIllegalParkingReport = (
  querys: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useReportQuery(
    '/mockServer/illegalParking/report',
    querys,
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  const dataSource = React.useMemo(() => {
    return convertData<any>({
      converter: d => d,
    })(data as any)
  }, [data])

  return {
    dataSource,
    ...others,
  }
}

export const usePedestrianFlowReport = (
  querys: { [key: string]: any },
  options?: QueryVariables
) => {
  const { data, ...others } = useReportQuery(
    '/mockServer/pedestrianFlow/report',
    querys,
    {
      enabled: !isNil(querys),
      ...options,
    }
  )

  return {
    dataSource: data || {},
    ...others,
  }
}
