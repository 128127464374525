import {
  GroundOverlay,
  OverlayView,
  OverlayViewF,
} from '@react-google-maps/api'
import { Device } from '@services/device'
import { imageUrlPrefix } from '@shared/env'
import React from 'react'
import { css } from 'styled-components'

// 建立一個方法，能夠傳入經緯度中心點與公尺半徑兩個參數，並回傳一個從中心點向外擴展公尺半徑的矩形範圍的 bounds
function getBounds(
  center: google.maps.LatLngLiteral,
  radius: number
): google.maps.LatLngBounds {
  const circle = new google.maps.Circle({
    center,
    radius,
  })
  return circle.getBounds() || new google.maps.LatLngBounds()
}

type IDensityOverlayProps = {
  center: google.maps.LatLngLiteral
  radius: number
  item: Device
}

export const DensityColor = {
  High: '#ff342b',
  Medium: '#FF8A00',
  Normal: '#6DD400',
}

const DensityOverlay = (props: IDensityOverlayProps) => {
  const { center, radius, item } = props

  const iconUrl = React.useMemo(() => {
    return `${imageUrlPrefix}/density/density_normal.png`
  }, [])

  const badgeColor = React.useMemo(() => {
    return DensityColor.Normal
  }, [])

  const bounds = getBounds(center, radius)

  return (
    <>
      <GroundOverlay url={iconUrl} bounds={bounds} />
      <OverlayViewF
        bounds={getBounds(center, radius)}
        position={center}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
        <div
          css={css`
            display: flex;
            width: 100%;
            height: 100%;
            align-items: flex-end;
            justify-content: center;
            padding: 15px;
          `}>
          <div
            css={css`
              background-color: ${badgeColor};
              color: #fff;
              font-weight: 600;
              font-size: 14px;
              border-radius: 20px;
              padding: 2px 8px;
            `}>
            {item?.displayName}
          </div>
        </div>
      </OverlayViewF>
    </>
  )
}

export default DensityOverlay
