export const DASHBOARD = '/dashboard'

export const MAP_DASHBOARD = '/map-dashboard'

export const STREETLIGHT_MANAGEMENT = '/street-light-management'

export const SWITCHBOX_MANAGEMENT = '/device/switchbox-management'

export const CAMERA_MANAGEMENT = '/device/camera-management'

export const TALKIE_MANAGEMENT = '/device/talkie-management'

export const WDETECT_MANAGEMENT = '/device/wdetect-management'

export const GATEWAY_MANAGEMENT = '/device/gateway-management'

export const INVERTER_MANAGEMENT = '/device/inverter-management'

export const REPAIR_MANAGEMENT = '/facility/repair-list'

export const CLOSED_REPAIR_MANAGEMENT = '/facility/closed-repair-list'

export const MAINTENANCE_MANAGEMENT = '/facility/maintenance-list'

export const MEDIA_SCREEN_MANAGEMENT = '/device/media-screen-management'

export const MAP_MANAGEMENT = '/map-management'

export const POWERSUPPLIER_MANAGEMENT = '/device/power-supplier-management'

export const WEATHER_MANAGEMENT = '/device/weather-station-management'

export const MAP_POSITIONING = '/system/map-positioning'

export const SOS_MANAGEMENT = '/device/sos-management'
