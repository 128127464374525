import React from 'react'
import { useQuery } from 'react-query'
import { ifElse, pathOr, isNil, head, compose } from 'ramda'
import {
  QueryVariables,
  useMakeMutation,
  request,
  MutationOptions,
} from '@shared/innmaxLib/services'
import { mapIndexed } from '@shared/innmaxLib/utils/webHelper'
import type {
  EpaperContent,
  EpaperContentBody,
  EpaperContentDetail,
  Marquee,
  Media,
} from './epaper.type'

export const useEpaperContentList = ({
  variables = {},
  ...options
}: QueryVariables = {}) => {
  const { data, ...others } = useQuery(['/epdContent/list', variables], {
    ...options,
    cacheTime: 0,
    select: (res: any) => {
      const content = compose(
        mapIndexed((x: EpaperContent, index: number) => ({
          ...x,
          index: index + 1,
        })),
        pathOr([], ['data'])
      )(res) as any
      return { content, total: content.length }
    },
  })

  return {
    ...others,
    dataSource: data,
    loading: others.isLoading,
  }
}

export const useNewEpaperContent = (options: MutationOptions = {}) => {
  return useMakeMutation(
    (values: Partial<EpaperContentBody>) =>
      request('/epdContent', { method: 'POST', body: values }),
    options
  )
}

export const useEditEpaperContent = (options: MutationOptions = {}) => {
  return useMakeMutation(
    (values: Partial<EpaperContentBody>) =>
      request('/epdContent/{id}', { method: 'PUT', body: values }),
    options
  )
}

export const useDeleteEpaperContent = (options: MutationOptions = {}) => {
  return useMakeMutation(
    (id: number) => request(`/epdContent/${id}`, { method: 'DELETE' }),
    options
  )
}

export const useEpaperDetail = (id?: number) => {
  const { data, ...others } = useQuery(['/epdContent/{id}/preview', { id }], {
    enabled: !!id,
    cacheTime: 0,
  })

  const dataSource = React.useMemo(() => {
    return compose(
      ifElse(
        isNil,
        () => undefined,
        (x: EpaperContentDetail) => {
          const marquees = mapIndexed(
            (x: Marquee, index: number) => ({
              ...x,
              index: index + 1,
              id: index + 1,
            }),
            pathOr([], ['marquees'], x)
          )

          const medias = mapIndexed((x: Media, index: number) => {
            return {
              ...x,
              index: index + 1,
              id: index + 1,
              type: compose(
                pathOr(undefined, ['type']),
                head,
                pathOr([], ['sources'])
              )(x), //MODE type
            }
          }, pathOr([], ['medias'], x))

          const wholePointMedia = {
            ...pathOr([], ['wholePointMedia'], x),
            type: compose(
              pathOr(undefined, ['type']),
              head,
              pathOr([], ['wholePointMedia', 'sources'])
            )(x), //MODE type
          }

          return { ...x, marquees, medias, wholePointMedia }
        }
      ),
      pathOr(undefined, ['data'])
    )(data)
  }, [data])

  return {
    ...others,
    data: dataSource as EpaperContentDetail | undefined,
  }
}
